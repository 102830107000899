import styled from '@emotion/styled';
import MaterialTextField, { TextFieldProps } from '@mui/material/TextField';

export function TextField(props: TextFieldProps) {
  return <TextInput {...props} variant="outlined" />;
}

const TextInput = styled(MaterialTextField)`
  > div {
    height: 40px;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.fontSize.m};
    background-color: ${({ theme }) => theme.palette.background.default};
    border-radius: ${({ theme }) => theme.border.radius};
    border: ${({ theme, disabled }) =>
    disabled ? '0px' : `1px solid ${theme.palette.secondary.main}`};
    :hover {
      border: ${({ theme, disabled }) =>
    disabled ? 'inherits' : `1px solid ${theme.palette.drawer.hoverColor}`};
    }
    :active {
      border: ${({ theme, disabled }) =>
    disabled ? 'inherits' : `2px solid ${theme.palette.primary.main}`};
    }
  }
  fieldset {
    border: 0px;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.primary.light};
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.primary.light}
      inset;
  }

  .MuiOutlinedInput-input {
    padding: 8px;
  }

  & .Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: not-allowed;
  }
  & .MuiInputBase-root.Mui-error {
    border: ${({ theme, disabled }) =>
    disabled ? '0px' : `1px solid ${theme.palette.messages.error}`};
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
  }

  > div {
    background: ${({ theme, disabled }) =>
    disabled ? theme.palette.button.disabled : 'inherits'};
  }
`;
