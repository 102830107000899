import { Modal } from './Modal';
import { Text } from 'components/Typography';
import { Container, GridItem } from 'components/Layout';
import { MainModalContainer } from './ModalLayout';
import { PrimaryButton } from './Button';
import { config } from 'core/config';

interface Props {
  isOpen: boolean;
  continueHandler: () => void;
  logoutHandler: () => void;
}

export function RenewTokenModal({
  isOpen,
  logoutHandler,
  continueHandler,
}: Props) {
  const { totalIdleTime, popupLogoutTime } = { ...config };
  const timeout = totalIdleTime - popupLogoutTime;
  return (
    <Modal isOpen={isOpen} title="Message">
      <MainModalContainer direction="column">
        <GridItem className="text">
          <Text>
            Your session has been idle for {timeout} minutes. To ensure your
            security, you will be logged out after {popupLogoutTime} minutes of
            inactivity.
          </Text>
          <Text>Do you want to continue with your current session?</Text>
        </GridItem>
        <Container direction="row" alignItems="center" justify="space-evenly">
          <PrimaryButton onClick={continueHandler}>Yes</PrimaryButton>
          <PrimaryButton onClick={logoutHandler}>No</PrimaryButton>
        </Container>
      </MainModalContainer>
    </Modal>
  );
}
