import { ReactElement } from 'react';
// import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { muiTheme } from './theme';

interface Props {
  children: ReactElement;
}

export default function EmotionTheme({ children }: Props): ReactElement {
  return (
    // <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
    // </ThemeProvider>
  );
}
