import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';
import MaterialCheckbox from '@mui/material/Checkbox';
import { ChangeEvent } from 'react';

export interface CheckboxProps {
  color?: 'primary' | 'secondary' | 'default';
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  labelWeight?: string;
  className?: string;
  disabled?: boolean;
}

export function Checkbox({
  color = 'primary',
  checked,
  label,
  onChange,
  className,
  labelWeight,
  disabled,
}: CheckboxProps) {
  return (
    <CheckboxLabel
      label={label}
      labelWeight={labelWeight}
      disabled={disabled}
      control={
        <Check
          disabled={disabled}
          color={color}
          checked={checked}
          className={className}
          // icon={
          //   <svg className="MuiSvgIcon-root" fill="none">
          //     <StyledRect x="3" y="3" rx="1" fill="none" />
          //   </svg>
          // }
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
        />
      }
    />
  );
}

const CheckboxLabel = styled(FormControlLabel) <{
  labelWeight?: string;
  disabled?: boolean;
}>`
  .MuiFormControlLabel-label {
    font-size: ${({ theme }) => theme.fontSize.s};
    color: ${({ theme, disabled }) =>
    !disabled ? theme.palette.primary.main : theme.palette.primary.disabled};
    font-weight: ${({ labelWeight }) => labelWeight};
  }

  &.MuiFormControlLabel-root.Mui-disabled {
    cursor: not-allowed;
  }
`;

const Check = styled(MaterialCheckbox)`
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
    &.Mui-disabled {
      color: ${({ theme }) => theme.palette.primary.disabled};
    }
  }
`;

// const StyledRect = styled.rect`
//   width: 16px;
//   height: 16px;
//   stroke: ${({ theme }) => theme.palette.secondary.main};
//   stroke-width: 1px;
// `;
