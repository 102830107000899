import styled from '@emotion/styled';
import { useInMobile } from 'core/Theming/Device';
import { Container, GridItem } from './Layout';
import dashboard from 'assets/Dashboard.png';
import logo from 'assets/Logo_dashboard.png';
import { Text } from 'components/Typography';

interface Props {
  headerText: string;
  children: React.ReactNode;
}
export function Home({ headerText, children }: Props) {
  const isMobile = useInMobile();
  return (
    <MainContainer wrap="nowrap">
      {!isMobile && (
        <Background img={dashboard}>
          <LogoImg src={logo} />
        </Background>
      )}
      <RightContent>
        <Text fontSize="xxl" fontWeight="bold">
          {headerText}
        </Text>
        <Description>{children}</Description>
      </RightContent>
    </MainContainer>
  );
}

const MainContainer = styled(Container)`
  flex: 1;
`;

const Background = styled.div<{ img: string }>`
  position: relative;
  background-image: url(${({ img }) => img});
  background-size: cover;
  flex: 1;
  height: 100%;
`;

const LogoImg = styled.img`
  position: absolute;
  right: -92px;
  top: 92px;
`;

const RightContent = styled(GridItem)`
  flex: 2;
  padding-top: 256px;
  padding-left: 84px;
  padding-right: 84px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Description = styled.div`
  margin-top: 25px;
  max-width: 671px;
`;

export const DescriptionText = styled(Text)`
  margin-bottom: 16px;
`;
