import styled from '@emotion/styled';

export const TextArea = styled.textarea`
  padding: 12px;
  resize: none;
  font-size: ${({ theme }) => theme.fontSize.s};
  border-radius: ${({ theme }) => theme.border.radius};
  background: ${({ theme }) => theme.palette.primary.light};
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  :hover {
    border: ${({ theme }) => `1px solid ${theme.palette.drawer.hoverColor}`};
  }
  :active {
    border: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
  }
  ::placeholder {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
