/** @jsxImportSource @emotion/react */

import {
  Grid,
  GridWrap,
  GridDirection,
  GridSpacing,
  GridSize,
} from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { css, useTheme } from '@emotion/react';

interface MainProps {
  children: ReactNode;
}

type justify = "start" | "stretch" | "unset" | "right" | "left" | "inherit" | "center" | "end" | "normal" | "space-around" | "space-between" | "space-evenly" | "revert" | "flex-end" | "flex-start" | undefined
type align = "start" | "stretch" | "unset" | "baseline" | "self-end" | "inherit" | "center" | "end" | "normal" | "revert" | "flex-end" | "flex-start" | "self-start" | undefined

interface Props {
  children: ReactNode;
  justify?: justify;
  alignItems?: align;
  wrap?: GridWrap;
  spacing?: GridSpacing;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
  className?: string;
  onClick?: () => void;
  id?: string;
  onScroll?: ({
    isOnTop,
    isOnBottom,
  }: {
    isOnTop: boolean;
    isOnBottom: boolean;
  }) => void;
}

type GridItemProps = Omit<Props, 'justify' | 'alignItems'>;

interface ContainerProps extends Props {
  direction?: GridDirection;
}

export function MainContainer({ children }: MainProps) {
  const theme = useTheme();

  return (
    <Grid
      css={css`
        background-color: ${theme.palette.background.default};
        display: flex;
        height: 100%;
      `}
    >
      {children}
    </Grid>
  );
}

export function Container({
  className,
  children,
  direction,
  justify,
  alignItems,
  spacing,
  wrap,
  xs,
  sm,
  md,
  lg,
  xl,
  onClick,
  id,
}: ContainerProps) {
  return (
    <Grid
      className={className}
      container
      spacing={spacing}
      direction={direction}
      justifyContent={justify}
      alignItems={alignItems}
      wrap={wrap}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      id={id}
      onClick={onClick}
    >
      {children}
    </Grid>
  );
}

export const GridItem = forwardRef<HTMLDivElement, GridItemProps>(
  function GridItem(
    { children, wrap, xs, sm, md, lg, xl, className, onClick, id, onScroll },
    ref
  ) {
    return (
      <Grid
        ref={ref}
        className={className}
        item
        wrap={wrap}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        onClick={onClick}
        id={id}
        onScroll={(event) => {
          if (onScroll)
            onScroll({
              isOnTop: event.currentTarget.scrollTop === 0,
              isOnBottom:
                Math.floor(
                  event.currentTarget.scrollHeight -
                  event.currentTarget.scrollTop
                ) <= event.currentTarget.clientHeight,
            });
        }}
      >
        {children}
      </Grid>
    );
  }
);
