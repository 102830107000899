import styled from '@emotion/styled';
import { GridItem } from './Layout';
import { UpperInfoContainer } from './PageLayout';
import ErrorIcon from '@mui/icons-material/Error';
import { Text } from 'components/Typography';

interface Props {
  children: string;
}

export default function InfoText({ children }: Props) {
  return (
    <InfoTextContainer wrap="nowrap">
      <IconContainer className="icon">
        <ErrorIcon />
      </IconContainer>
      <GridItem>
        <Text>{children}</Text>
      </GridItem>
    </InfoTextContainer>
  );
}

const InfoTextContainer = styled(UpperInfoContainer)`
  margin-top: ${({ theme }) => theme.margin.m};
  align-items: center;
`;

const IconContainer = styled(GridItem)`
  height: 24px;
  margin-right: 14px;
`;
