interface RowPerPageStorage {
  getAvailableRowsPerPage: () => number[];
  initialValue: number;
  get: () => Promise<number | { isCorrupted: boolean }>;
  set: (value: number) => Promise<void>;
}

class RowPerPageLocalStorage implements RowPerPageStorage {
  private localStorageRowsPerPageKey = 'rowsPerPage';

  initialValue: number;

  constructor() {
    const storageValue = this.getItem();
    this.initialValue =
      typeof storageValue === 'number'
        ? storageValue
        : this.getAvailableRowsPerPage()[0];
  }

  getAvailableRowsPerPage = () => [10, 25, 50];

  public get() {
    return Promise.resolve(this.getItem());
  }

  private getItem() {
    const value = Number(localStorage.getItem(this.localStorageRowsPerPageKey));
    return this.getAvailableRowsPerPage().indexOf(value) > -1
      ? value
      : { isCorrupted: true };
  }

  public set(value: number) {
    if (this.getAvailableRowsPerPage().indexOf(value) > -1) {
      this.initialValue = value;
      return Promise.resolve(
        localStorage.setItem(this.localStorageRowsPerPageKey, value.toString())
      );
    } else {
      return Promise.reject(`Provided row count ${value} is not supported.`);
    }
  }
}

export const rowCountPerPageStorage = new RowPerPageLocalStorage();
