import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { config } from 'core/config';

const reactPlugin = new ReactPlugin();
const appInsights =
  config.useAppInsightsConnectionLogs == 'true'
    ? new ApplicationInsights({
        config: {
          connectionString: config.appInsightsConnectionString,
          extensions: [reactPlugin],
          enableAutoRouteTracking: true,
          autoTrackPageVisitTime: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
        },
      })
    : null;

if (process.env.NODE_ENV !== 'test' && config.useAppInsightsConnectionLogs == 'true') {
  appInsights?.loadAppInsights();
}

appInsights?.addTelemetryInitializer((env:ITelemetryItem) => {
  // specify env.tags and env.data 
    env.data = env.data ?? [];
    env.data.dd_service = config.appInsightsDDService;
    env.data.dd_env = config.loggingEnvironment;
    env.data.ApplicationCode= config.appInsightsDDService;
});

export { reactPlugin, appInsights };