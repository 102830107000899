import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
interface Props {
  children: JSX.Element | null;
}

export const useInDesktop = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.values.md + 1 });
  return isDesktop;
};

export const useInMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.values.md });
  return isMobile;
};

export const DesktopContainer = ({ children }: Props) => {
  const isDesktop = useInDesktop();
  return isDesktop ? children : null;
};

export const MobileContainer = ({ children }: Props) => {
  const isMobile = useInMobile();
  return isMobile ? children : null;
};
