import styled from '@emotion/styled';
import { Backdrop as MaterialBackdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
interface Props {
  className?: string;
}
export function Loader({ className }: Props) {
  return (
    <div>
      <CircularProgress className={className} />
    </div>
  );
}

export const GrayBackdrop = styled(MaterialBackdrop)`
  z-index: 1300;
  background: rgba(0, 0, 0, 0.2);
`;
