import { useEffect, useState } from 'react';
import { rowCountPerPageStorage } from './RowsPerPageStorage';

export const useRowCountPerPage = () => {
  const initialValue = rowCountPerPageStorage.initialValue;
  const [state, setState] = useState(initialValue);
  const [error, setError] = useState<string | undefined>(undefined);
  const setItemsPerPage = (value: number) => {
    return rowCountPerPageStorage
      .set(value)
      .then(() => setState(value))
      .catch(setError);
  };

  useEffect(() => {
    (async function () {
      let value: number = initialValue;
      const result = await rowCountPerPageStorage.get();
      if (typeof result === 'number') {
        value = result;
      } else {
        value = initialValue;
        await rowCountPerPageStorage.set(initialValue);
      }
      setState(value);
    })();
  }, [initialValue]);

  return {
    itemsPerPage: state,
    setItemsPerPage,
    availableRowsPerPage: rowCountPerPageStorage.getAvailableRowsPerPage(),
    error,
  };
};
